/**
 * 文書カテゴリ 検索画面定義ファイル
 * 概要
 * 画面単位でのフロント定義について記載する
 * 更新履歴
 * 2022/03/26 追加 尾坂
 */

/** 検索結果の1ページあたりに表示するレコード数を指定する **/
const listPerPage = 10;
/**
 * 検索画面の検索項目について定義する
 * ※データベース参照型を利用する場合は明示的な指定が必要となる
 * グリッド指定で表示位置を指定する
 * col: X座標, row: Y座標, sizeX: 横幅, sizeY: 縦幅
 */
const searchForm = [
  {
    display_id: 'category_id',
    col: 0,
    row: 0,
    sizeX: 4,
    sizeY: 1
  },
  {
    display_id: 'category_name',
    col: 4,
    row: 0,
    sizeX: 4,
    sizeY: 1
  }
];

/** 検索画面 検索結果表示テーブルカラム **/
const ColumnList: Array<{ [k: string]: string | number }> = [
  { name: 'category_id', order: 0 },
  { name: 'category_name', order: 1 }
];

/** 検索パネルのGrid1区間の横幅（%） **/
const SearchPanelColumnWidth = 5;

/** 検索時の初期表示Option **/
const StatusSelectInitialOption: { text: string; value: string[] } = {
  text: '全ステータス',
  value: []
};
/** 検索結果からの一括処理時の各メッセージ **/
const bulkMessage: { [k: string]: string } = {
  noItems: 'アイテムを選択してください',
  notMatch: 'アイテムステータスが一致していません',
  apiSuccess: '処理が完了しました',
  apiError: '以下のアイテムの処理が失敗いたしました'
};
/** 画面中央ガイダンス表示部分の文言を指定 **/
const initializeDescriptiveText =
  'ここにガイダンス文言を設定できます。\n' + 'ここにガイダンス文言を設定できます。';

export default {
  listPerPage,
  searchForm,
  ColumnList,
  SearchPanelColumnWidth,
  StatusSelectInitialOption,
  bulkMessage,
  initializeDescriptiveText
};
