/*
 * 機能別コンポーネント 発注検索パネル画面で使用するconst・interface・types
 * ・更新履歴
 */

// 下部ボタン項目情報
export const underButtonArray = [
  {
    name: 'search',
    color: 'primary',
    value: '検索'
  },
  {
    name: 'clear',
    color: 'clearbtn',
    value: 'クリア'
    // text: true
  }
];

// 下部ボタン項目情報(CSV出力画面用)
export const csvUnderButtonArray = [
  {
    name: "csvOutput",
    color: "primary",
    value: "CSV出力"
  },
  {
    name: "clear",
    color: "clearbtn",
    value: "クリア",
    text: true
  }
];
